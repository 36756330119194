import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { StructuredText } from 'react-datocms';

import phone_svg from '../assets/svg/misc-58.svg';

const Faq = ({ header }) => {
	const [expanded, setExpanded] = useState(null);

	const toggle = (i) => {
		if (expanded === i) {
			return setExpanded(null);
		}

		setExpanded(i);
	};

	return (
		<section className="faq-section">
			<div className="container">
				<h2>{header}</h2>
				<div className="wrapper">
					<picture>
						<source srcSet={phone_svg} />
						<img src={phone_svg} />
					</picture>
					<faq className="faq">
						<StaticQuery
							query={graphql`
								query HeadingQuery {
									allDatoCmsFaq(
										filter: {}
										sort: { fields: meta___createdAt }
									) {
										edges {
											node {
												structuredanswer {
													value
												}
												question
												answ
											}
										}
									}
								}
							`}
							render={(data) => (
								<ul className="faq-list">
									{data.allDatoCmsFaq.edges.map((q) => (
										<motion.li
											className={`ng-star-inserted ${
												expanded === q ? 'active' : ''
											} ${q[0] ? 'active' : ''}`}
											onClick={() => toggle(q)}
										>
											<AnimatePresence>
												<header>
													<h3>{q.node.question}</h3>
													<div className="caret">
														<svg
															fill="#FFFFFF"
															version="1.1"
															xmlns="http://www.w3.org/2000/svg"
															xmlnsXlink="http://www.w3.org/1999/xlink"
															x="0px"
															y="0px"
															viewBox="0 0 24 24"
															xmlSpace="preserve"
														>
															<polygon points="22,5 12,15 2,5 0,7 12,19 24,7 " />
														</svg>
													</div>
												</header>
												<motion.main
													key="content"
													variants={{
														open: {
															opacity: 1,
															height: 'auto',
															paddingBottom:
																'calc(30px*var(--scale))',
														},
														collapsed: {
															opacity: 0,
															height: 0,
														},
													}}
													transition={{
														duration: 0.8,
														ease: [
															0.04, 0.62, 0.23,
															0.98,
														],
													}}
												>
													<StructuredText
														data={
															q.node
																.structuredanswer
														}
													/>
													{/* {q.node.answ} */}
												</motion.main>
											</AnimatePresence>
										</motion.li>
									))}
								</ul>
							)}
						/>
					</faq>
				</div>
			</div>
		</section>
	);
};

export default Faq;
