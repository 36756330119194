import * as React from 'react';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import News from '../components/news';
import { StaticImage } from 'gatsby-plugin-image';

import { motion } from 'framer-motion';

import Faq from '../components/faq';
import Partners from '../components/partners';
import Acta from '../components/acta';
import arrow_right from '../assets/svg/arrow-right-green.svg';
import bg_gradient from '../assets/svg/bg-gradient.svg';

const transition_short = { duration: 0.2, ease: [0.6, 0.01, -0.05, 0.9] };
const transition_long = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

const App = ({ data }) => {
	return (
		<Layout pageTitle="Gronn" background={true}>
			<motion.div id="hjem-header" className="intro">
				<div className="header">
					<motion.div
						className="container"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={transition_long}
					>
						<div className="hero">
							<style jsx>{`
								.masked-copy p {
									display: inline;
								}

								.masked-copy h2 {
									color: white;
									display: inline;
									/* Get the font sizes and other features from those set on the parent: */
									font: inherit;
								}

								.masked-copy {
									/* This is it! */
									-webkit-background-clip: text;
									color: transparent !important;
								}
							`}</style>
							<h1 style={{}}>
								<motion.div
									whileHover={{}}
									whileTap={{ scale: 0.9 }}
									style={{
										display: 'block',
										textAlign: 'left',
										position: 'relative',
										visibility: 'inherit',
										opacity: 1,
										transform: 'matrix(1, 0, 0, 1, 0, 0)',
									}}
									className=""
									drag
									dragConstraints={{
										top: -50,
										left: 0,
										right: 0,
										bottom: 0,
									}}
								>
									{data.datoCmsHomePage.title}
								</motion.div>
								<span
									className="white-line"
									style={{ color: '#2a2a2a' }}
								>
									{data.datoCmsHomePage.introHeaderTekst}
								</span>
							</h1>

							<p style={{ color: '#2a2a2a' }}>
								Vi utvikler prosjekter, finner
								finansieringsordninger, skriver søknader, er
								prosjektledere, administrerer prosjekter og
								rapporterer. <br /> Grønn Kompetanse har også
								lang erfaring med Start Ups.
							</p>
							<div
								className="image-grid"
								style={{ marginTop: '100px' }}
							>
								<div className="row top">
									<div className="col col-sm-12 left bordered-container">
										<StaticImage
											src="../assets/forsidebilde.jpg"
											alt="Forsidebilde med grønt natur motiv av blomster i forgrunn og et drivhus i bakgrunn."
											className="bordered-container"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="about">
							<div className="aos-init aos-animate">
								<h2>
									Hva{' '}
									<span className="hhk">
										Vi arbeider med bærekraft og grønne
										næringer i feltet mellom næringsliv,
										forskning/utvikling og finansiering.
									</span>
									<br />
									<br />
								</h2>
							</div>
							<div className="aos-init aos-animate">
								<h2>
									Hvordan{' '}
									<span className="hhk">
										Vi gir råd, søker, administrerer,
										drifter og rapporterer prosjekter og
										oppgaver.
									</span>
									<br />
									<br />
								</h2>
							</div>
							<div className="aos-init aos-animate">
								<h2>
									Kunder{' '}
									<span className="hhk">
										Du som vil arbeide for en bærekraftig
										framtid, skape noe nytt, utvikle en ide,
										løse utfordringer.
									</span>
									<br />
									<br />
								</h2>
							</div>

							<div className="aos-init">
								<h2></h2>
							</div>
							<div className="aos-init">
								<h2></h2>
							</div>
							<Link
								to="/tjenester"
								className="tdua black aos-init"
							>
								Våre tjenester
							</Link>
						</div>
					</motion.div>
				</div>
			</motion.div>

			<Partners
				kunderIntroTekst={data.datoCmsHomePage.kunderIntroTekst}
			/>

			<style jsx>{`
				.video-large {
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: #000;
					z-index: 9000000;
					padding: calc(46px * var(--scale));
				}
				.video-large,
				.video-large iframe {
					width: 100%;
					height: 100%;
				}
				.video-large .close {
					position: absolute;
					top: calc(20px * var(--scale));
					right: calc(20px * var(--scale));
					cursor: pointer;
					opacity: 0.8;
					transition: opacity 0.2s;
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					outline: none !important;
				}
				.video-large .close:active,
				.video-large .close:focus,
				.video-large .close:hover {
					outline: none !important;
					opacity: 1;
				}
				.video-large .close i {
					position: relative;
					display: block;
					width: calc(24px * var(--scale));
					height: calc(24px * var(--scale));
				}
				.video-large .close i:after,
				.video-large .close i:before {
					content: '';
					top: 11px;
					left: 2px;
					position: absolute;
					width: 20px;
					height: 2px;
					transform: rotate(45deg);
					background-color: #fff;
				}
				.video-large .close i:after {
					transform: rotate(-45deg);
				}
				.intro {
					color: #546db0;
					opacity: 1;
					transform: translateY(0);

					padding-bottom: calc(100px * var(--scale));
					transition: all 1.2s ease;
					transition-delay: 0s;
				}
				.intro.fade-out {
					opacity: 0;
					transform: translateY(-100px);
				}
				.intro.static {
					background: #042825;
				}

				.intro .hero h1 {
					font-weight: 400;
					letter-spacing: -1px;
					line-height: 1.11;
					margin-bottom: 2.5rem;
					max-width: calc(1000px * var(--scale));
				}
				@media (max-width: 1199px) {
					.intro .hero h1 {
						font-size: 4.25rem;
						max-width: 100%;
					}
				}
				@media (max-width: 991px) {
					.intro .hero h1 {
						font-size: 3.25rem;
					}
				}
				@media (max-width: 767px) {
					.intro .hero h1 {
						font-size: 3rem;
					}
				}
				@media (max-width: 575px) {
					.intro .hero h1 br {
						display: none;
					}
				}
				.intro p {
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.025em;
					max-width: calc(700px * var(--scale));
				}
				@media (min-width: 768px) and (max-width: 991px) {
					.intro p {
						font-size: 1.375rem;
					}
				}
				@media (max-width: 767px) {
					.intro p {
						font-size: 1.25rem;
					}
				}
				.intro .video {
					position: relative;
					height: 0;
					padding-top: 56.29228687%;
				}
				.intro .video .video-inner {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					cursor: pointer;
				}
				.intro .video .video-inner video {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					-o-object-position: center;
					object-position: center;
					font-family: 'object-fit: cover; object-position: center';
				}
				.intro .video .video-inner .video-overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(4, 40, 37, 0.25);
				}
				.intro .video .video-inner .video-play {
					z-index: 2;
				}
				.intro .video .video-inner .video-play,
				.intro .video .video-inner .video-play .circle {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: calc(120px * var(--scale));
					height: calc(120px * var(--scale));
				}
				.intro .video .video-inner .video-play .circle {
					border-radius: 50%;
					background: rgba(4, 40, 37, 0.7);
					-webkit-backdrop-filter: blur(8px);
					backdrop-filter: blur(8px);
					transform-origin: center center;
					transition: all 0.5s ease;
					transition-delay: 0s;
				}
				.intro .video .video-inner .video-play svg {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				.intro .video .video-inner .video-play:active .circle,
				.intro .video .video-inner .video-play:focus .circle,
				.intro .video .video-inner .video-play:hover .circle {
					width: calc(124px * var(--scale));
					height: calc(124px * var(--scale));
					background: rgba(4, 40, 37, 0.9);
				}
				.intro .partners {
					margin: 0;
					padding: 0;
					list-style: none;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					padding-top: calc(120px * var(--scale));
				}
				@media (max-width: 767px) {
					.intro .partners {
						display: none;
					}
				}
				.intro .partners li {
					display: block;
				}
				@media (min-width: 1200px) {
					.intro .partners li {
						transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
						opacity: 0;
						transform: translateY(100px) translateZ(0);
					}
				}
				@media (min-width: 1200px) {
					.intro .partners li:nth-child(4n + 1) {
						transition-delay: 0.08s;
					}
					.intro .partners li:nth-child(4n + 2) {
						transition-delay: 0.165s;
					}
					.intro .partners li:nth-child(4n + 3) {
						transition-delay: 0.33s;
					}
					.intro .partners li:nth-child(4n + 4) {
						transition-delay: 0.495s;
					}
					.intro .partners li:nth-child(4n + 5) {
						transition-delay: 0.4s;
					}
				}
				@media (max-width: 767px) {
					.intro .partners li {
						margin: 20px;
					}
				}
				.intro .partners li.aos-animate {
					opacity: 1 !important;
					transform: translateY(0) translateZ(0) !important;
				}
				@media (min-width: 576px) {
					.intro .partners li:first-child img {
						height: calc(20px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(2) img {
						height: calc(48px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(3) img {
						height: calc(22px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(4) img {
						height: calc(40px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(5) img {
						height: calc(25px * var(--scale));
					}
				}
				.intro .about {
					padding-top: calc(200px * var(--scale));
					padding-bottom: calc(200px * var(--scale));
					padding-left: 26%;
				}
				@media (min-width: 992px) and (max-width: 1199px) {
					.intro .about {
						padding-left: 20%;
					}
				}
				@media (max-width: 991px) {
					.intro .about {
						padding-left: 0;
						padding-top: calc(120px * var(--scale));
						padding-bottom: calc(120px * var(--scale));
					}
				}
				.intro .about h2 {
					font-weight: 400;
					letter-spacing: -0.025em;
					line-height: 1.17;
				}
				@media (min-width: 992px) and (max-width: 1199px) {
					.intro .about h2 {
						font-size: 2.625rem;
					}
				}
				@media (max-width: 991px) {
					.intro .about h2 {
						font-size: 2.375rem;
					}
				}
				@media (max-width: 767px) {
					.intro .about h2 {
						font-size: 2rem;
					}
				}
				.intro .about h2 span {
					color: #2a2a2a;
				}
				.intro .about > a {
					font-size: 1.5rem;
					letter-spacing: -1px;
					line-height: 1.33;
				}
				.projects-list-title {
					padding-top: calc(120px * var(--scale));
					padding-bottom: calc(120px * var(--scale));
				}
				@media (max-width: 767px) {
					.projects-list-title {
						padding-top: calc(80px * var(--scale));
						padding-bottom: calc(80px * var(--scale));
					}
				}
				.projects-list-title .container h2 {
					font-weight: 400;
					color: #042825;
					letter-spacing: -1px;
					line-height: 1.15;
					margin-bottom: 1.25rem;
				}
				.projects-list-title .container h2 span {
					display: block;
				}
				@media (max-width: 767px) {
					.projects-list-title .container h2 {
						font-size: 2rem;
					}
				}
				.projects-list-title .container p {
					max-width: 490px;
					font-size: 1.25rem;
				}
				.clients-section .container {
					position: relative;
				}
				.clients-section .container:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: calc(20px * var(--scale));
					right: calc(20px * var(--scale));
					height: 1px;
					background: #042825;
				}
				.clients-section .container > h2 {
					letter-spacing: -1px;
					line-height: 1.15;
					max-width: 600px;
					padding-top: calc(120px * var(--scale));
					margin-bottom: 1.25rem;
				}
				@media (max-width: 767px) {
					.clients-section .container > h2 {
						font-size: 2rem;
					}
				}
				.clients-section .container > p {
					max-width: 493px;
					padding-bottom: calc(80px * var(--scale));
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.02em;
				}
				.insights-section {
					padding-bottom: calc(200px * var(--scale));
				}
				.insights-section .container {
					position: relative;
				}
				.insights-section .container:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: calc(20px * var(--scale));
					right: calc(20px * var(--scale));
					height: 1px;
					background: #042825;
				}
				.about-section {
					position: relative;
					background: xc;
					color: #fff;
					padding-top: calc(120px * var(--scale));
				}
				.about-section .container > h1 {
					letter-spacing: -0.04em;
					line-height: 1.11;
					max-width: calc(850px * var(--scale));
					padding-bottom: calc(120px * var(--scale));
					text-align: center;
					margin: 0 auto;
				}
				.about-section .container > h2 {
					letter-spacing: -0.04em;
					line-height: 1.11;
					max-width: calc(850px * var(--scale));
					padding-bottom: calc(120px * var(--scale));
					text-align: center;
					margin: 0 auto;
				}

				@media (max-width: 1199px) {
					.about-section .container > h1 {
						font-size: 4.25rem;
					}
				}
				@media (max-width: 991px) {
					.about-section .container > h1 {
						font-size: 3.25rem;
					}
				}
				@media (max-width: 767px) {
					.about-section .container > h1 {
						font-size: 3rem;
					}
				}
				@media (max-width: 575px) {
					.about-section .container > h1 {
						font-size: 2.25rem;
					}
				}
				.about-section .row-about {
					margin-bottom: calc(120px * var(--scale));
				}
				@media (min-width: 768px) {
					.about-section .row-about {
						display: flex;
						align-items: center;
						margin-left: -1.25rem;
						margin-right: -1.25rem;
					}
				}
				.about-section .row-about .image {
					margin-bottom: 1.25rem;
				}
				@media (min-width: 768px) {
					.about-section .row-about .image {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
						margin-bottom: 0;
					}
				}
				.about-section .row-about .image img,
				.about-section .row-about .image picture {
					display: block;
					max-width: 100%;
					width: 100%;
				}
				@media (min-width: 768px) {
					.about-section .row-about .text {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
					}
				}
				@media (min-width: 992px) {
					.about-section .row-about .text {
						padding-left: calc(8.33333333% + 1.25rem);
					}
				}
				@media (max-width: 767px) {
					.about-section .row-about .text {
						padding-top: 20px;
					}
				}
				.about-section .row-faq {
					display: flex;
					flex-direction: column-reverse;
				}
				@media (min-width: 768px) {
					.about-section .row-faq {
						flex-direction: row;
						margin-left: -1.25rem;
						margin-right: -1.25rem;
					}
				}
				@media (min-width: 768px) {
					.about-section .row-faq .text {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
					}
				}
				.about-section .row-faq .text h2 {
					margin-bottom: 1.25rem;
					letter-spacing: -0.025em;
					line-height: 1.18;
				}
				@media (min-width: 768px) and (max-width: 991px) {
					.about-section .row-faq .text h2 {
						font-size: 2.25rem;
					}
				}
				@media (max-width: 767px) {
					.about-section .row-faq .text h2 {
						font-size: 1.5rem;
					}
				}
				.about-section .row-faq .text p {
					max-width: 493px;
				}
				.about-section .row-faq .image {
					margin-bottom: 2.5rem;
					text-align: center;
				}
				@media (min-width: 768px) {
					.about-section .row-faq .image {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
						margin-bottom: 0;
					}
				}
				@media (min-width: 992px) {
					.about-section .row-faq .image {
						padding-left: calc(8.33333333% + 1.25rem);
					}
				}
				@media (max-width: 767px) {
					.about-section .row-faq .image {
						padding-top: 20px;
					}
				}
				.about-section a,
				.about-section p {
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.02em;
				}
				@media (min-width: 768px) and (max-width: 991px) {
					.about-section a,
					.about-section p {
						font-size: 1.375rem;
					}
				}
				@media (max-width: 767px) {
					.about-section a,
					.about-section p {
						font-size: 1.25rem;
					}
				}
			`}</style>
			<Acta />

			{/* <News title={true} showAll={true} /> */}

			{/* <Faq header={data.datoCmsHomePage.faqOverskrift} /> */}
		</Layout>
	);
};

export default App;

export const Head = () => (
	<>
		<title>Grønn Næringskompetanse</title>

		<meta name="author" content="Bjørnar Bjelland" />
		<meta name="copyright" content="Grønn Næringskompetanse" />
		<meta name="robots" content="follow" />
		<meta name="language" content="Norwegian"></meta>
		<meta name="revisit-after" content="7 days"></meta>
		<meta
			name="description"
			content="Et rådgivningsselskap innen bedriftsutvikling og forskning. Vi utvikler prosjekter, finner finansieringsordninger, skriver søknader og er prosjektledere."
		/>
		<meta
			name="keywords"
			content="Grønn Næringskompetanse, Hjem, Hjemmeside, firma, bedriftsutvikling, forskning, finansiering, søknadsskriving"
		/>
	</>
);
export const query = graphql`
	query HomeQuery {
		datoCmsHomePage {
			introHeaderTekst
			kunderIntroTekst
			nyheterIntroTekst
			title
			faqOverskrift
		}
	}
`;
