import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import gnk_logo_text from '../assets/svg/LOGO_TEKST.svg';
import biotext from '../assets/logos/biotext.svg';
import misc_58 from '../assets/svg/misc-58.svg';

const Acta = ({ data }) => {
	return (
		<>
			<style jsx>{`
				.hero .container .approach {
					text-align: center;
				}

				.hero .container .approach h1 {
					font-size: 3rem;
					letter-spacing: -0.025em;
					line-height: 1.18;
					max-width: 1026px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 2.5rem;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.hero .container .approach h1 {
						font-size: 2.25rem;
					}
				}

				@media (max-width: 767px) {
					.hero .container .approach h1 {
						font-size: 1.5rem;
					}
				}

				.hero .container .approach > a {
					font-size: 1.5rem;
					letter-spacing: -0.025em;
					line-height: 1.33;
				}

				.hero .container .approach ul {
					list-style: none;
					padding: 0;
					margin: 0;
					margin-top: calc(160px * var(--scale));
				}

				@media (min-width: 768px) {
					.hero .container .approach ul {
						display: flex;
						align-items: top;
						margin-left: -1.25rem;
						margin-right: -1.25rem;
					}
				}

				.hero .container .approach ul li {
					margin-bottom: calc(100px * var(--scale));
				}

				.approach ul li {
					justify-content: flex-end;
					display: flex;

					align-items: flex-start;
					flex-direction: column;
				}

				@media (min-width: 768px) {
					.hero .container .approach ul li {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
					}
				}

				.hero .container .approach ul li h2 {
					font-size: 6.25rem;
					margin-bottom: 1.25rem;
					line-height: 1.08;
					letter-spacing: -0.04em;
				}

				@media (max-width: 1199px) {
					.hero .container .approach ul li h2 {
						font-size: 4.25rem;
					}
				}

				@media (max-width: 991px) {
					.hero .container .approach ul li h2 {
						font-size: 3.25rem;
					}
				}

				@media (max-width: 767px) {
					.hero .container .approach ul li h2 {
						font-size: 3rem;
					}
				}

				.hero .container .approach ul li h2 span {
					font-size: 3rem;
					vertical-align: baseline;
				}

				.hero .container .approach ul li p {
					max-width: calc(387px * var(--scale));
					margin: 0 auto;
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.02em;
				}
			`}</style>
			<div className="hero">
				<div className="container">
					<div className="approach" style={{ marginTop: '100px' }}>
						<h1>
							Siden oppstarten i 2011 har Grønn Næringskompetanse
							fått tilslag på, administrert og rapportert mer enn
							80 prosjekter i tillegg til generell rådgivning for
							bedrifter. Selskapet har i dag to faste ansatte.
						</h1>
						<a
							routerlink="/kontant"
							className="tdua thick"
							href="/kontakt"
						>
							Kontakt oss
						</a>
						<ul>
							<li>
								<h2>
									Bjørnar<span> Bjelland</span>
								</h2>
								<p>
									Spesialisert innen brukerstyrte prosjekter.
									Tar imot kunder med et smil, og prøver
									alltid å finne den beste mulig løsningen.
								</p>
							</li>
							<li>
								<StaticImage
									src="../assets/portraits/portrett_bjornar_2.jpg"
									alt="Portrett av ansatt Bjørnar Bjelland, med trær i bakgrunn."
									className="bordered-container"
									style={{ maxWidth: '100%' }}
								/>
							</li>
						</ul>
					</div>

					<div className="approach">
						<ul>
							<li>
								<h2>
									Lars<span> Gundersen</span>
								</h2>
								<p>
									Spesialisert innen kundebehandling og å
									finne sammarbeidspartnere som er så relevant
									som mulig for kunden.
								</p>
							</li>
							<li>
								<StaticImage
									src="../assets/portraits/portrett_lars_2.jpg"
									alt="Portrett av ansatt Bjørnar Bjelland, med trær i bakgrunn."
									className="bordered-container"
									style={{ maxWidth: '100%' }}
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Acta;
