import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { render } from 'datocms-structured-text-generic-html-renderer';

const News = ({ iteration, title, showAll }) => {
	return (
		<StaticQuery
			query={graphql`
				query ArticlesQuery {
					allDatoCmsArticle(
						sort: { fields: publishDate, order: DESC }
					) {
						edges {
							node {
								slug
								excerpt
								title
								thumbnail {
									url
								}
							}
						}
					}
				}
			`}
			render={(data) => (
				<section
					className="block-container"
					style={{
						// backgroundColor: 'rgb(234, 234, 234)',
						color: '#000',
					}}
				>
					<style jsx>{`
						.block-custom-posts__posts {
							margin: 0 calc(-20px * var(--scale));
							padding: 0;
							list-style: none;
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-ms-flex-wrap: wrap;
							flex-wrap: wrap;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
						}
						.block-custom-posts__posts.columns-3 li {
							width: 33.33%;
						}
						.custom-post-article,
						.block-custom-posts__posts li article {
							display: block;
						}
						.block-custom-posts__posts li {
							margin-bottom: calc(80px * var(--scale));
							padding: 0 calc(20px * var(--scale));
						}
						.custom-post-article .image,
						.block-custom-posts__posts li article .image {
							margin-bottom: 20px;
						}
						.custom-post-article .text .title,
						.block-custom-posts__posts li article .text .title {
							-webkit-transition: all 0.2s ease;
							-o-transition: all 0.2s ease;
							transition: all 0.2s ease;
							-webkit-transition-delay: 0s;
							-o-transition-delay: 0s;
							transition-delay: 0s;
							display: block;
							font-weight: 500;
							line-height: 1.33;
							font-size: calc(24px * var(--scale));
							text-decoration: underline;
							-webkit-text-decoration-color: transparent;
							text-decoration-color: transparent;
						}
						.custom-post-article .image .img-link img,
						.block-custom-posts__posts
							li
							article
							.image
							.img-link
							img {
							width: 100%;
							height: auto;
						}
						@media (max-width: 767.98px) {
							.block-custom-posts__posts li {
								width: 100% !important;
							}
							.custom-post-article .text .title,
							.block-custom-posts__posts li article .text .title {
								font-size: 1.125rem !important;
							}
							.block-cta-partners__posts li {
								width: 50% !important;
								padding: 0 30px;
								margin-bottom: 60px;
							}
							.block-cta-partners__posts li:nth-child(2n + 1) {
								padding-right: 20px;
							}
							.intro .hero h1 {
								font-size: 2.22rem;
							}
						}
						.custom-post-article .text .title,
						.block-custom-posts__posts li article .text .title {
							-webkit-transition: all 0.2s ease;
							-o-transition: all 0.2s ease;
							transition: all 0.2s ease;
							-webkit-transition-delay: 0s;
							-o-transition-delay: 0s;
							transition-delay: 0s;
							display: block;
							font-weight: 500;
							line-height: 1.33;
							font-size: calc(24px * var(--scale));
							text-decoration: underline;
							-webkit-text-decoration-color: transparent;
							text-decoration-color: transparent;
						}
					`}</style>
					<div className="container">
						<section>
							<block className="block-custom-posts">
								<h2
									className="block-custom-posts__title"
									style={{
										textAlign: 'center',
										marginBottom: 'calc(80px*var(--scale))',
									}}
								>
									{title
										? 'Tidligere arbeid vi har gjennomført'
										: ''}
								</h2>
								<ul className="block-custom-posts__posts columns-3">
									{data.allDatoCmsArticle.edges.map(
										(article) => (
											<>
												<li>
													<Link
														to={`/nyheter/${article.node.slug}/`}
													>
														<article>
															<div className="image">
																<a
																	href=""
																	className="img-link"
																>
																	<img
																		src={`${article.node.thumbnail.url})`}
																		className="attachment-small size-small wp-post-image"
																		alt=""
																		loading="lazy"
																		width={
																			387
																		}
																		height={
																			445
																		}
																	/>
																</a>
															</div>
															<div className="text">
																<a
																	className="title"
																	href=""
																>
																	{
																		article
																			.node
																			.title
																	}
																</a>
																<footer className="links">
																	<a
																		className="link-arrow"
																		href=""
																	>
																		<i className="icon-arrow-tail i1" />
																		<i className="icon-arrow-tail i2" />
																		<i className="icon-arrow-right-tip i3" />
																	</a>
																</footer>
															</div>
														</article>
													</Link>
												</li>
											</>
										)
									)}
								</ul>
								<style jsx>{`
									.show-more-case a {
										text-decoration: none;
										font-size: 1.5rem;
										color: #042825;
										letter-spacing: -1px;
										line-height: 1.33;
									}
								`}</style>

								{showAll ? (
									<div className="block-custom-posts__cta show-more-case">
										<Link to="/nyheter/" className="tdua">
											Vis alle nyhetene
										</Link>
									</div>
								) : (
									''
								)}
							</block>
						</section>
					</div>
				</section>
			)}
		/>
	);
};

export default News;
